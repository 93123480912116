import React, { Component } from "react";
import { MOBILE, TABLET, PC } from "./global";

import SwiperY from "./swipery";
// import Scrollspy from 'react-scrollspy'
import MainSlider from "./mainslider";
import Company from "./company";
import Business from "./business";
import Research from "./research";
import Partners from "./partners";
import Footer from "./footer";

export default class MainPage extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {};
    }
    //mui fade기능 구현을 위한 함수
    // mui를 이용해서 만들려 하였으나 aos사용
    onSlideChange = (activeIndex) =>
    {
        if (activeIndex === 1)
        {
            this.setState({ fadeCompany: true, fadeBusinees: false, fadeResearch: false });
        }
        else if (activeIndex === 2)
        {
            this.setState({ fadeCompany: false, fadeBusinees: true, fadeResearch: false });
        }
        else
        {
            this.setState({ fadeCompany: false, fadeBusinees: false, fadeResearch: false });
        }
    }

    render()
    {

        return (
            <>
                <PC>
                    <SwiperY paginationNames={["About", "Company", "Business", "Join us","Partners"]} >
                        <MainSlider />
                        <Company />
                        <Business />
                        <Research />
                        <Partners />
                        <Footer />
                    </SwiperY>
                </PC>
                <TABLET>
                    <MainSlider />
                    <Company />
                    <Business />
                    <Research />
                    <Partners />
                    <Footer />
                </TABLET>
                <MOBILE>
                        <MainSlider />
                        <Company />
                        <Business />
                        <Research />
                        <Partners />
                        <Footer />
                </MOBILE>
            </>
        )
    }
};
