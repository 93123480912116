import React, { Component } from "react";
import "../business.css";
import { Link } from "react-router-dom";

import { Box } from "@mui/system";
import { Typography, Button } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import { styled } from '@mui/material/styles';
import styledc from 'styled-components';

import AOS from "aos";
import "aos/dist/aos.css";

import { MOBILE, TABLET, PC, DEFAULT_DELAY } from "./global";

const BoxWrap = styledc.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
`;

// const StyledGrid = styled(Grid)`
// && {
//     margin: 0 auto;
//     max-width: 950px;
// 	padding: 0px;
// }`;

const SubBox = styledc.div`
position: relative;
background: #000;
width: 100%;
height: auto;
padding-bottom: 66.67%;
cursor: pointer;
overflow: hidden;
margin: 8px;
@media screen and (max-width: 1000px) {
    width: 90%;
}
`;

const ImageButton = styledc.img`
width: 100%;
height: 100%;
object-fit: cover;
transition: all 0.3s ease-in-out;
`;

const Info = styledc.div`
position: absolute;
left: 0;
bottom: 0;
color: #fff;
width: 100%;
padding: 15px;
box-sizing: border-box;
opacity: 0;
transition: all 0.3s ease-in-out;
`;

const InfoTitle = styledc.h3`
font-size: 30px;
line-height: 30px;
margin: 0px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
`;

const MoInfoTitle = styledc.h3`
font-size: 20px;
line-height: 30px;
margin: 0px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
`;

const BoxBefore = styledc.div`
content: "";
background: #fff;
width: 0px;
height: 1px;
position: absolute;
right: 20px;
top: 20px;
transition: all 0.35s ease;
`;

const BoxAfter = styledc.div`
content: "";
background: #fff;
width: 1px;
height: 0px;
position: absolute;
right: 20px;
top: 20px;
transition: all 0.35s ease;
`;

const ImageButtonWrap = styledc.div`
position: absolute;
width: 100%;
height: 100%;
top: 0;
left: 0;
&:hover {
    ${ImageButton} {
    opacity: 0.2;
    }
    ${BoxBefore} {
    width: 60px;
    }
    ${BoxAfter} {
    height: 60px;
    }
    ${Info} {
    opacity: 1;
    }
}
`;

export default class Business extends Component
{
	componentDidMount()
	{
		AOS.init();
	}

	render()
	{
		const products = [
			[{
				title: "BIOS-mini",
				image: process.env.PUBLIC_URL + "/assets/images/minid1.png",
				delay: 500,
			},
			{
				title: "BIOS-S8",
				image: process.env.PUBLIC_URL + "/assets/images/S8d1.png",
				delay: 600,
			}],
			[{
				title: "BIOS-S24",
				image: process.env.PUBLIC_URL + "/assets/images/S24d1.png",
				delay: 700,
			},
			{
				title: "BIOS-S40",
				image: process.env.PUBLIC_URL + "/assets/images/S40d1.png",
				delay: 500,
			}]
		];

		return (
			<>
				<PC>
					<Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "100vh", backgroundColor: "black" }}>
						<Box display="flex" justifyContent="center" alignItems="center">
							<Box m={5} data-aos="fade-right" data-aos-delay="500" data-aos-duration="1500" data-aos-easing="ease-in-out">
								<Typography mb={3} variant="h3" sx={{ fontWeight: "bold", color: "white" }}>주요상품</Typography>
								<Typography variant="h6" sx={{ fontWeight: "bold", width: "100%", color: "white" }}>BioBrain의 <span style={{ color: "#00a0b9", fontWeight: "bold" }}>혁신적인 생체 계측 솔루션</span>은</Typography>
								<Typography variant="h6" sx={{ fontWeight: "bold", width: "100%", color: "white" }}>첨단 기술과 사용자 중심의 디자인을 결합하여 </Typography>
								<Typography variant="h6" sx={{ fontWeight: "bold", width: "100%", color: "white" }}>개인의 건강 관리 방식을 혁신적으로 변화시킵니다.</Typography>
							</Box>
							<Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "50%", height: "100vh", backgroundColor: "black" }}>
								<Box p={5} width="100%" justifyContent="column">
									<div style={{ width: "550px" }}>
										{
											products.map((row, r) =>
											{
												return (
													<div style={{ display: "flex", flexDirection: "row" }}>
														{
															row.map((col, c) =>
															{
																const index = ((r * 2) + c);
																const delay = DEFAULT_DELAY + (index * 100);
																return (
																	<BoxWrap>
																		<SubBox data-aos="zoom-in" data-aos-delay={delay} data-aos-duration="900">
																			<ImageButtonWrap >
																				<Link to="/productdetails" state={{ selectedId: index }}>
																					<ImageButton
																						src={col.image} alt="상품 이름"
																					/>
																					<BoxBefore />
																					<BoxAfter />
																					<Info>
																						<InfoTitle>{col.title}</InfoTitle>
																					</Info>
																				</Link>
																			</ImageButtonWrap>
																		</SubBox>
																	</BoxWrap>
																);
															})
														}
													</div>
												);
											})
										}
									</div>
									<Box mt={2}>
										<Link to="/productpage" style={{ textDecoration: 'none' }}>
											<Button sx={{ color: "white" }} >
												<Typography>MORE PRODUCT</Typography><NavigateNextIcon/>
											</Button>
										</Link>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</PC>
				<TABLET>
					<Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%", backgroundColor: "black", paddingBottom:"100px" }}>
						<Box justifyContent="column">
							<Box paddingY={5} data-aos="fade-down" data-aos-delay="500" data-aos-duration="700" data-aos-easing="ease-in-out">
								<Typography paddingY={2} variant="h3" sx={{ fontWeight: "bold", color: "white" }}>주요상품</Typography>
								<Typography variant="h6" sx={{ fontWeight: "bold", color: "white" }}>맞춤형 건강 관리의 미래를 여는</Typography>
								<Typography variant="h6" sx={{ fontWeight: "bold", color: "white" }}>BioBrain만의 <span style={{ color: "#00a0b9", fontWeight: "bold" }}>혁신적인 생체계측 솔루션</span></Typography>
							</Box>
							<Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", backgroundColor: "black" }}>
								<Box width="100%" justifyContent="column">
									<div style={{ width: "400px" }}>
										{
											products.map((row, r) =>
											{
												return (
													<div style={{ display: "flex", flexDirection: "row" }}>
														{
															row.map((col, c) =>
															{
																const index = ((r * 2) + c);
																const delay = DEFAULT_DELAY + (index * 100);

																return (
																	<BoxWrap>
																		<SubBox data-aos="zoom-in" data-aos-delay={delay} data-aos-duration="900">
																			<ImageButtonWrap >
																				<Link to="/productdetails" state={{ selectedId: index }}>
																					<ImageButton
																						src={col.image} alt="상품 이름"
																					/>
																					<BoxBefore />
																					<BoxAfter />
																					<Info>
																						<MoInfoTitle>{col.title}</MoInfoTitle>
																					</Info>
																				</Link>
																			</ImageButtonWrap>
																		</SubBox>
																	</BoxWrap>
																);
															})
														}
													</div>
												);
											})
										}
									</div>
									<Box mt={2}>
										<Link to="/productpage" style={{ textDecoration: 'none' }}>
											<Button sx={{ color: "white" }} >
												<Typography>MORE PRODUCT</Typography><NavigateNextIcon />
											</Button>
										</Link>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</TABLET>
				<MOBILE>
					<Box display="flex" justifyContent="center" alignItems="center" mt={5} sx={{ width: "100%", height: "100%", backgroundColor: "black" }}>
						<Box justifyContent="column">
							<Box m={4} data-aos="fade-down" data-aos-delay="500" data-aos-duration="500" data-aos-easing="ease-in-out">
								<Typography mb={1} variant="h4" sx={{ fontWeight: "bold", color: "white" }}>주요상품</Typography>
								<Typography variant="body2" sx={{ fontWeight: "bold", color: "white" }}>맞춤형 건강 관리의 미래를 여는</Typography>
								<Typography variant="body2" sx={{ fontWeight: "bold", color: "white" }}>BioBrain만의 <span style={{ color: "#00a0b9", fontWeight: "bold" }}>혁신적인 생체계측 솔루션</span></Typography>
							</Box>
							<Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%", backgroundColor: "black" }}>
								<Box p={5} width="100%" justifyContent="column">
									<div style={{ width: "300px" }}>
										{
											products.map((row, r) =>
											{
												return (
													<div style={{ display: "flex", flexDirection: "row" }}>
														{
															row.map((col, c) =>
															{
																const index = ((r * 2) + c);
																const delay = DEFAULT_DELAY + (index * 100);

																return (
																	<BoxWrap>
																		<SubBox data-aos="zoom-in" data-aos-delay={delay} data-aos-duration="900">
																			<ImageButtonWrap >
																				<Link to="/productdetails" state={{ selectedId: index }}>
																					<ImageButton
																						src={col.image} alt="상품 이름"
																					/>
																					<BoxBefore />
																					<BoxAfter />
																					<Info>
																						<MoInfoTitle>{col.title}</MoInfoTitle>
																					</Info>
																				</Link>
																			</ImageButtonWrap>
																		</SubBox>
																	</BoxWrap>
																);
															})
														}
													</div>
												);
											})
										}
									</div>
									<Box mt={2}>
										<Link to="/productpage" style={{ textDecoration: 'none' }}>
											<Button sx={{ color: "white" }} >
												<Typography>MORE PRODUCT</Typography><NavigateNextIcon />
											</Button>
										</Link>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</MOBILE>
			</>
		);
	}
};