import React, { Component } from 'react';
import { Box, Typography, Button, Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

import { MOBILE, TABLET, PC } from "./global";
import Footer from './footer';

const TabBox = styled(Button)({
	width: "300px",
	height: "100px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	margin: "20px",
	textTransform: "none",
	backgroundColor: "black",
	whiteSpace: "pre-line",
	color: "white",
	border: "1px solid",
	borderImage: "linear-gradient(0deg, #8360c3 0%, #2ebf91 100%)",
	borderImageSlice: 1,
	transition: "transform 0.3s",
	'&:hover': {
		transform: "scale(1.1)",
		cursor: "pointer",
	},
});

const VideoTabBox = styled(Button)(({ bgImage }) => ({
	width: "330px",
	height: "250px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	margin: "20px",
	textTransform: "none",
	backgroundColor: "black",
	color: "white",
	border: "1px solid",
	borderImage: "linear-gradient(0deg, #8360c3 0%, #2ebf91 100%)",
	borderImageSlice: 1,
	transition: "transform 0.3s",
	backgroundImage: `url(${bgImage})`,
	backgroundSize: "cover",
	backgroundPosition: "center",
	'&:hover': {
		transform: "scale(1.1)",
		cursor: "pointer",
	},
}));

const MobileTabBox = styled(Button)({
	width: "200px",
	height: "100px",
	margin: 5,
	backgroundColor: "black",
	whiteSpace: "pre-line",
	color: "white",
	border: "1px solid",
	borderImage: "linear-gradient(0deg, #8360c3 0%, #2ebf91 100%)",
	borderImageSlice: 1,
	transition: "transform 0.3s",
	'&:hover': {
		transform: "scale(1.1)",
		cursor: "pointer",
	},
});

class Ai extends Component
{
	constructor(props)
	{
		super(props);
		this.state = { dialogOpen: false, videoUrl: "" };
	}

	componentDidMount()
	{
		AOS.init();
	}

	handleDialogOpen = (tab) =>
	{
		// "OMNES VIAE AD BIOBRAIN DVCVNT" 탭 클릭 시에만 다이얼로그를 열도록 조건 설정
		if (tab.title === "OMNES VIAE AD BIOBRAIN DVCVNT")
		{
			this.setState({ dialogOpen: true, videoUrl: tab.url });
		}
		else
		{
			window.open(tab.url, "_blank");
		}
	}


	handleVideDialogOpen = (url) =>
	{
		// 다이얼로그가 열릴 URL을 전달하여 설정
		this.setState({ dialogOpen: true, videoUrl: url });
	}

	handleDialogClose = () =>
	{
		this.setState({ dialogOpen: false, videoUrl: "" });
	}

	render()
	{
		const tabs = [
			{ title: "바이오브레인 언어지능 - 아수(我誰)", url: "http://ai.biobrain.kr/" },
			{ title: "바이오브레인 시각언어지능 멀티도메인지능 - 드림캐쳐(Dreamcatcher)", url: "http://ai.biobrain.kr/dreamcatcher" },
			{ title: "글(契)을 그셔(鍥) 다시 봐요 \n (Web-Note)", url: "https://biobrain.kr/web-note/" },
			{ title: "얼굴 감성 인식", url: "https://biobrain.kr/faceapi/" },
			{ title: "얼굴 인식 ( 안중근, 유관순, 윤봉길 등)", url: "https://biobrain.kr/face-recognition/" },
			// { title: "얼굴 방향 찾기(좌, 우, 상, 하)", url: "https://biobrain.kr/face-direction/" },
			{ title: "동영상 원격 PPG 기반 심박수 측정", url: "https://biobrain.kr/heartrate/" },
			{ title: "웹캠으로 사람을 인식, 다양한 필터를 사용할 수 있음", url: "https://biobrain.kr/selfie-filter/" },
			{ title: "웹캠에서 사람을 인식, 제거", url: "https://biobrain.kr/selfie/" },
			{ title: "웹캠으로 사람을 인식, 만화 필터 적용", url: "https://biobrain.kr/cartoon/" },
			{ title: "웹캠으로 시선추척", url: "https://biobrain.kr/gazer/" },
			{ title: "창문인가, 디스플레이인가 (MagicViewer)", url: "https://biobrain.kr/magicviewer/" },
			{ title: "Thy Body Landmarks to Thy Acupuncture Points", url: "https://biobrain.kr/acupoint/" },
			{ title: "바이오브레인 Web-SRS", url: "https://biobrain.kr/stt-tts/" },
			{ title: "Inside Out & Outside In \n( AI가 생성한 공간 경험하기 )", url: "https://biobrain.kr/insideout/" },
			{ title: "한글리그래피", url: "https://biobrain.kr/hangle/" },
			{ title: "OMNES VIAE AD BIOBRAIN DVCVNT", url: "https://biobrain.kr/OmnesViae/" },
			{ title: "Context Is All AI Need for Humanity", url: "http://121.179.123.76:5007/" },
			{ title: "Catching up with AI Trader", url: "http://121.179.123.76:5006/" },
		];

		const videoTabs = [
			{ url: "https://drive.google.com/file/d/1FBey37HJhZ2mcNMTGSPknjdKb5ClyXfk/preview", image: "/assets/images/video1.png" },
			{ url: "https://drive.google.com/file/d/1U7l_5HRluuF8vLhkUQFREPD4IXaSWK_j/preview", image: "/assets/images/video2.png" },
			{ url: "https://drive.google.com/file/d/1gFlEfTcq71OG1Qh07Ax2NF__crUGM_52/preview", image: "/assets/images/video3.png" },
		];

		return (
			<>
				<PC>
					<Box sx={{ height: "100%", backgroundColor: "black", paddingTop: 15 }} display="flex" justifyContent="center" marginBottom="150px">
						<Box height="50%" width="50%" display="flex" flexDirection="column">
							<Box sx={{ marginTop: 10, marginBottom: 5 }}>
								<Typography variant='h3' sx={{ marginLeft: 5, color: 'white' }}>Artificial Intelligence</Typography>
								<Typography variant='subtitle1' sx={{ paddingTop: 1, marginLeft: 6, color: 'white' }}>"인공지능은 인류애의 거울이다, 거울이어야 한다, 거울이되길 희망한다.</Typography>
								<Typography variant='subtitle1' sx={{ width: "450px", marginLeft: 7, color: 'white', display: "flex", justifyContent: "flex-end" }}> - 바이오브레인 김기성"</Typography>
							</Box>
							<Box
								display="flex"
								justifyContent="center"
								flexWrap="wrap"
								gap={1}
								data-aos="fade-up"
								data-aos-delay="500"
								data-aos-duration="900"
							>
								{tabs.map((tab, index) => (
									<TabBox key={index} onClick={() => this.handleDialogOpen(tab)}>
										<Typography variant='subtitle1'>{tab.title}</Typography>
									</TabBox>
								))}
							</Box>
						</Box>
						<Box height="50%" width="50%" display="flex" flexDirection="column">
							<Box sx={{ marginTop: 10, marginBottom: 5 }}>
								<Typography variant='h3' sx={{ marginLeft: 5, color: 'white' }}>Space Computing</Typography>
								<Typography variant='subtitle1' sx={{ paddingY: 1, marginLeft: 6, color: 'white' }}>"기술은 원래 예술이쥬 - 비비 킴"</Typography>
							</Box>
							<Box
								display="flex"
								justifyContent="center"
								flexWrap="wrap"
								gap={1}
								data-aos="fade-up"
								data-aos-delay="500"
								data-aos-duration="900"
							>
								{videoTabs.map((videoTab, index) => (
									<VideoTabBox
										key={index}
										bgImage={videoTab.image}
										onClick={() => this.handleVideDialogOpen(videoTab.url)}
									/>
								))}
								<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: 10 }}>
									<Typography variant='subtitle1' sx={{ color: 'white', fontStyle: 'italic', fontSize: 26 }}>AI est speculum humanitatis; speculum esse debet, et speramus ut fiat.</Typography>
									<Typography variant='subtitle1' sx={{ color: 'white', fontStyle: 'italic', fontSize: 26 }}>AI is a mirror of humanity; it should be a mirror, and we hope it becomes one..</Typography>
									<Typography variant='subtitle1' sx={{ color: 'white', fontStyle: 'italic', fontSize: 26 }}>人工智能乃仁愛之鏡，當為鏡，願成鏡。</Typography>
								</Box>
							</Box>
						</Box>
						<Dialog
							open={this.state.dialogOpen}
							onClose={this.handleDialogClose}
							maxWidth="lg"
							PaperProps={{ style: { overflow: "hidden", width: "600px", height: "600px", borderRadius: "20px" }, }}
						>
							<DialogContent sx={{ overflow: "hidden", padding: 0, display: "flex", justifyContent: "center", alignItems: "center", height: "100%", }} >
								<iframe
									src={this.state.videoUrl}
									title="Dialog Content"
									style={{
										width: "100%",
										height: "100%",
										border: "none",
										objectFit: "cover",
										objectPosition: "center",
									}}
									allow="autoplay"
								></iframe>
							</DialogContent>
						</Dialog>
					</Box>
					<Footer />
				</PC >
				<TABLET>
					<Box sx={{ width: "100%", height: "100%", backgroundColor: "black", marginBottom: "100px" }} >
						<Box data-aos="fade-up" data-aos-delay="500" data-aos-duration="900" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
							<Box height="100%" paddingTop="150px">
								<Box sx={{ marginTop: 10, marginBottom: 5 }}>
									<Typography variant='h3' sx={{ marginLeft: 5, color: 'white' }}>Artificial Intelligence</Typography>
									<Typography variant='subtitle1' sx={{ paddingTop: 1, marginLeft: 6, color: 'white' }}>"인공지능은 인류애의 거울이다, 거울이어야 한다, 거울이되길 희망한다.</Typography>
									<Typography variant='subtitle1' sx={{ width: "450px", marginLeft: 10, color: 'white', display: "flex", justifyContent: "flex-end" }}>- 바이오브레인 김기성"</Typography>
								</Box>
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									flexWrap="wrap"
									gap={1}
									data-aos="fade-up"
									data-aos-delay="500"
									data-aos-duration="900"
									paddingBottom={5}
								>
									{tabs.map((tab, index) => (
										<Link to={tab.url} target="_blank" key={index} style={{ textDecoration: 'none' }}>
											<MobileTabBox>
												<Typography variant='subtitle2' sx={{ textTransform: "none" }}>{tab.title}</Typography>
											</MobileTabBox>
										</Link>
									))}
								</Box>
							</Box>
							<Box height="100%" marginBottom="200px">
								<Box sx={{ marginTop: 10, marginBottom: 5 }}>
									<Typography variant='h3' sx={{ marginLeft: 5, color: 'white' }}>Space Computing</Typography>
									<Typography variant='subtitle1' sx={{ paddingY: 1, marginLeft: 6, color: 'white' }}>"기술은 원래 예술이쥬 - 비비 킴"</Typography>
								</Box>
								<Box
									display="flex"
									justifyContent="center"
									flexWrap="wrap"
									gap={1}
									data-aos="fade-up"
									data-aos-delay="500"
									data-aos-duration="900"
								>
									{videoTabs.map((videoTab, index) => (
										<VideoTabBox
											key={index}
											bgImage={videoTab.image}
											onClick={() => this.handleVideDialogOpen(videoTab.url)}
										/>
									))}
									<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: 10 }}>
										<Typography variant='subtitle1' sx={{ color: 'white', fontStyle: 'italic', fontSize: 22 }}>AI est speculum humanitatis; speculum esse debet, et speramus ut fiat.</Typography>
										<Typography variant='subtitle1' sx={{ color: 'white', fontStyle: 'italic', fontSize: 22 }}>AI is a mirror of humanity; it should be a mirror, and we hope it becomes one..</Typography>
										<Typography variant='subtitle1' sx={{ color: 'white', fontSize: 22 }}>人工智能乃仁愛之鏡，當為鏡，願成鏡。</Typography>
									</Box>
								</Box>
								<Dialog
									open={this.state.dialogOpen}
									onClose={this.handleDialogClose}
									maxWidth="lg"
									PaperProps={{
										style: {
											overflow: "hidden",
											width: "400px",
											height: "300px",
										},
									}}
								>
									<DialogContent sx={{ overflow: "hidden", padding: 0 }}>
										<iframe
											src={this.state.videoUrl}
											title="Dialog Content"
											width="600px"
											height="600px"
											allow="autoplay"
											style={{
												border: "none",
												overflow: "hidden",
												width: "100%",
												height: "100%",
											}}
										></iframe>
									</DialogContent>
								</Dialog>
							</Box>
						</Box>
					</Box >
					<Footer />
				</TABLET>
				<MOBILE>
					<Box sx={{ width: "100%", height: "100%", backgroundColor: "black", marginY: "100px" }} >
						<Box data-aos="fade-up" data-aos-delay="500" data-aos-duration="900" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
							<Box sx={{ marginTop: 10, marginBottom: 5 }}>
								<Typography variant='h3' sx={{ marginLeft: 5, color: 'white' }}>Artificial Intelligence</Typography>
								<Typography variant='subtitle1' sx={{ marginLeft: 5, color: 'white' }}>인공지능은 인류애의 거울이다,</Typography>
								<Typography variant='subtitle1' sx={{ marginLeft: 10, color: 'white' }}>거울이어야 한다, 거울이되길 희망한다.</Typography>
								<Typography variant='subtitle1' sx={{ width: "100%", color: 'white', display: "flex", justifyContent: "flex-end" }}> - 바이오브레인 김기성"</Typography>
							</Box>
							<Box
								height="50%"
								display="flex"
								justifyContent="center"
								alignItems="center"
								flexWrap="wrap"
								gap={1}
								data-aos="fade-up"
								data-aos-delay="500"
								data-aos-duration="900"
							>
								{tabs.map((tab, index) => (
									<Link to={tab.url} target="_blank" key={index} style={{ textDecoration: 'none' }}>
										<MobileTabBox>
											<Typography variant='subtitle2' sx={{ textTransform: "none" }}>{tab.title}</Typography>
										</MobileTabBox>
									</Link>
								))}
							</Box>
							<Box sx={{ marginTop: 20, marginBottom: 5 }}>
								<Typography variant='h3' sx={{ marginLeft: 5, color: 'white' }}>Space Computing</Typography>
								<Typography variant='subtitle1' sx={{ paddingY: 1, marginLeft: 6, color: 'white' }}>"기술은 원래 예술이쥬 - 비비 킴"</Typography>
							</Box>
							<Box
								display="flex"
								justifyContent="center"
								flexWrap="wrap"
								gap={1}
								data-aos="fade-up"
								data-aos-delay="500"
								data-aos-duration="900"
							>
								{videoTabs.map((videoTab, index) => (
									<VideoTabBox
										key={index}
										bgImage={videoTab.image}
										onClick={() => this.handleVideDialogOpen(videoTab.url)}
									/>
								))}
								<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: 5 }}>
									<Typography variant='subtitle1' sx={{ color: 'white', fontStyle: 'italic', fontSize: 22 }}>AI est speculum humanitatis; speculum esse debet, et speramus ut fiat.</Typography>
									<Typography variant='subtitle1' sx={{ color: 'white', fontStyle: 'italic', fontSize: 22 }}>AI is a mirror of humanity; it should be a mirror, and we hope it becomes one..</Typography>
									<Typography variant='subtitle1' sx={{ color: 'white', fontSize: 22 }}>人工智能乃仁愛之鏡，當為鏡，願成鏡。</Typography>
								</Box>
							</Box>
							<Dialog
								open={this.state.dialogOpen}
								onClose={this.handleDialogClose}
								maxWidth="lg"
								PaperProps={{
									style: {
										overflow: "hidden",
										width: "400px",
										height: "300px",
									},
								}}
							>
								<DialogContent sx={{ overflow: "hidden", padding: 0 }}>
									<iframe
										src={this.state.videoUrl}
										title="Dialog Content"
										width="850px"
										height="850px"
										allow="autoplay"
										style={{
											border: "none",
											overflow: "hidden",
											width: "100%",
											height: "100%",
										}}
									></iframe>
								</DialogContent>
							</Dialog>
						</Box>
					</Box >
					<Footer />
				</MOBILE >
			</>
		);
	}
};

export default Ai;