import React, { Component } from "react";
import "../App.css";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import AOS from "aos";
import "aos/dist/aos.css";

import { MOBILE, TABLET, PC } from "./global";

export default class Company extends Component
{
	componentDidMount()
	{
		AOS.init();
	}

	componentWillUnmount()
	{
		clearInterval(this.waveInterval);
	}

	createWave = () =>
	{
		const waveContainer = this.refs.waveContainer;
		const wave = document.createElement('div');
		wave.className = 'wave';
		waveContainer.appendChild(wave);

		wave.addEventListener('animationend', () =>
		{
			wave.remove();
		});
	};

	render()
	{
		return (
			<>
				<PC>
					<Box position="relative" sx={{ width: "100%", height: "100vh", backgroundColor: "black" }}>
						<Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100vh" }}>
							<Box display="flex" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
								<Box display="flex" justifyContent="center" alignItems="center">
									<Box marginY={2} component="img" sx={{ width: "550px", height: "450px" }} src={process.env.PUBLIC_URL + '/assets/images/cozy.webp'} />
									<Box m={5} justifyContent="row">
										<Box>
											<Typography variant="h3" sx={{ color: "white", fontWeight: "bold" }}>바이오 테크와</Typography>
											<Typography variant="h3" sx={{ color: "white", fontWeight: "bold" }}><span style={{ color: "#00a0b9" }}>AICBM</span> 융합</Typography>
											<Typography variant="h3" sx={{ color: "white", fontWeight: "bold" }}> 솔루션 기업, BioBrain</Typography>
										</Box>
										<Box mt={5}>
											<Typography variant="h6" sx={{ fontWeight: "bold", color: "white" }}>생체 신호 측정, 의료 영상 시스템</Typography>
											<Typography variant="h6" sx={{ fontWeight: "bold", color: "white" }}>생체정보 분석 기술 등 보유하고</Typography>
											<Typography variant="h6" sx={{ fontWeight: "bold", color: "white" }}><span style={{ color: "#00a0b9" }}>  AI, IoT, 클라우드, 빅데이터, 모바일 </span>
												융합을 통해 </Typography>
											<Typography variant="h6" sx={{ fontWeight: "bold", color: "white" }}>혁신적인 진단 장치를 연구, 개발, 제조 및 판매합니다.</Typography>
											{/* <Typography variant="h6" sx={{ fontWeight: "bold", color: "white" }}>진단 장치를 연구, 개발, 제조 및 판매합니다.
											</Typography> */}
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box >
				</PC >
				<TABLET>
					<Box sx={{ width: "100vw", height: "100%", backgroundColor: "black", paddingTop: "100px", paddingBottom: "100px" }}>
						<Box p={8} display="flex" flexDirection="column">
							<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "100px" }}>
								{/* <Box component="img" sx={{ width: "400px", height: "250px", opacity: "0.6" }} src={process.env.PUBLIC_URL + '/assets/images/mainslide5.jpg'} /> */}
								<Box component="img" sx={{ width: "550px", height: "400px" }} src={process.env.PUBLIC_URL + '/assets/images/cozy.webp'} />
							</Box>
							<Box width="100%" display="flex" justifyContent="center" alignItems="center" data-aos="fade-left" data-aos-duration="800">
								<Box mt={5} display="flex" flexDirection="column">
									<Box>
										<Typography variant="h3" sx={{ color: "white", fontWeight: "bold" }}>바이오 테크과<span style={{ color: "#00a0b9" }}>AICBM</span> 융합 </Typography>
										<Typography variant="h3" sx={{ color: "white", fontWeight: "bold" }}>솔루션 기업, BioBrain</Typography>
									</Box>
									<Box mt={5}>
										<Typography variant="h6" sx={{ color: "white", fontWeight: "600" }}>생체 신호 측정, 의료 영상 시스템, 생체정보 분석 기술 등 보유하고
											<span style={{ color: "#00a0b9" }}>  AI, IoT, 클라우드, 빅데이터, 모바일 </span></Typography>
										<Typography variant="h6" sx={{ color: "white", fontWeight: "600" }}>융합을 통해 혁신적인 진단 장치를 연구, 개발, 제조 및 판매합니다.</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</TABLET>
				<MOBILE>
					<Box sx={{ height: "100%", backgroundColor: "black"  }}>
						<Box width="100%" marginY={10}>
							<Box>
								<Box marginY={2} component="img" sx={{ width: "100%", height: "300px" }} src={process.env.PUBLIC_URL + '/assets/images/cozy.webp'} />
							</Box>
							<Box width="100%" data-aos="zoom-in" data-aos-duration="1000">
								<Box m={1} >
									<Typography variant="h4" sx={{ color: "white", fontWeight: "bold" }}>바이오 기술과 <span style={{ color: "#00a0b9" }}>AICBM</span></Typography>
									<Typography variant="h4" sx={{ color: "white", fontWeight: "bold" }}>융합 솔루션 기업, BioBrain</Typography>
								</Box>
								<Box m={1}>
									<Typography variant="body2" sx={{ color: "white", fontWeight: "600" }}>생체 신호 측정, 의료 영상 시스템, 생체정보 분석 기술 등 보유하고
										<span style={{ color: "#00a0b9" }}>  AI, IoT, 클라우드, 빅데이터, 모바일 </span>
										융합을 통해 혁신적인 진단 장치를 연구, 개발, 제조 및 판매합니다.
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</MOBILE>
			</>
		);
	}
};

